<template>
	<div>
		<div class="person_infor">
			<div class="login_top">
				<!-- <div class="img"> <img src="@/assets/login/flag.png"></div> -->
				<div class="login_title" @click="backClick">{{ $t('myAd') }}</div>
				<div class="front_img">
					<img src="@/assets/home_icons/front.png">
				</div>
				<div class="sub_title" style="font-size: 18px;">{{ $t('order.editAd') }}</div>
			</div>
			<div class="all_list">
				<div class="list_item">
					<div class="list_item_top">
						<span>*</span>{{ $t('order.adPosition') }}
					</div>
					<div class="list_item_bottom">
						<el-select v-model="ads_type_id" :placeholder="$t('order.pleaseSelectAdPosition')" clearable
							@change="selectChange" disabled>
							<el-option v-for="item in positionList" :key="item.value" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="list_item">
					<div class="list_item_top">
						<span>*</span>{{ $t('order.adContent') }}
					</div>
					<div class="list_item_bottom">
						<el-select v-model="information_id" :placeholder="$t('order.pleaseSelectAdContent')" clearable
							v-loadmore="loadMore" disabled>
							<el-option v-for="item in myAdContentList" :key="item.id" :label="item.title" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="list_item" v-if="end_time">
					<div class="list_item_top">
						<!-- <span>*</span> -->
						{{ $t('endTime') }}
					</div>
					<div class="list_item_bottom">
						<!-- <el-input :placeholder="$t('order.pleaseSelectAdDuration')" type="number" v-model="num"
							clearable>
						</el-input> -->
						{{ end_time }}
					</div>
				</div>
				<div class="list_item" style="border-bottom: none;">
					<div class="list_item_top">
						<!-- <span>*</span> -->
						{{ $t('order.photo') }}({{ $t('order.aSheet') }})
					</div>
					<div class="list_item_bottom">
						<div class="all_imgs">
							<el-upload :file-list="fileList" :action="uploadImgUrl" list-type="picture-card"
								:on-preview="handlePictureCardPreview" :on-remove="handleRemove" :limit="1"
								:before-upload="beforeUpload" :on-change="onChange" :multiple="true">
								<div slot="tip" class="el-upload__tip">{{ $t('atMostPhoto') }}</div>
								<i class="el-icon-plus"></i>
							</el-upload>
							<el-dialog :visible.sync="previewImgDialog" :modal="false">
								<img width="100%" :src="dialogImageUrl" alt="">
							</el-dialog>
						</div>
					</div>
				</div>
				<div class="btn" @click="btnClick">
					<div>{{ $t('submit') }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {
	uploadImgUrl
} from '@/common/utils/config.js';

export default {
	data() {
		return {
			uploadImgUrl: uploadImgUrl,
			dialogImageUrl: '', //预览图片地址
			img_list: [], //上传图片列表
			previewImgDialog: false,

			num: '', //广告天数
			positionList: [], //广告位置列表
			ads_type_id: '', //广告位id
			adPrice: 0, //广告价格

			information_id: '', //

			page: 1,
			pageSize: 10,
			total: '',
			myAdContentList: [], //所有广告内容

			adInfor: '', //
			id: '',
			end_time: '', //到期时间

			fileList: [], //回显展示图片
		}
	},
	mounted() {
		this.id = this.$route.query.id;
		this.get_ads_list();
		this.getAllAd();
		this.myAdsInfo();
	},
	methods: {
		// 获取编辑广告的信息
		myAdsInfo() {
			this.$http.myAdsInfo({
				id: this.id
			}).then(res => {
				if (res.code == 1) {
					this.adInfor = res.data;
					this.ads_type_id = this.adInfor.ads_type;
					this.information = this.adInfor.information_name;
					this.information_id = this.adInfor.bind_information_id;
					this.end_time = this.adInfor.end_time;
					this.fileList = [{
						name: this.adInfor.thumb,
						url: this.adInfor.thumb_url
					}]
				} else {
					this.$message.info(res.msg);
				}
			})
		},



		// 所选广告内容下拉加载更多
		loadMore() {
			if (this.myAdContentList.length < this.total) {
				this.page++;
			}
		},
		getAllAd() {
			let obj = {
				page: this.page,
				pageSize: this.pageSize,
				check: 2,
			}
			this.$http.msgList(obj).then(res => {
				if (res.code == 1) {
					this.myAdContentList = res.data.data;
					this.total = res.data.total;
					console.log("-0999任", this.myAdContentList);
				} else {

				}
			})
		},
		// 下拉框筛选事件
		selectChange(e) {
			console.log(e);
			this.adPrice = this.positionList.filter(item => item.id == e)[0].price;
			console.log(this.adPrice);
		},
		// 获取广告位置
		get_ads_list() {
			this.$http.get_ads_list().then(res => {
				if (res.code == 1) {
					console.log();
					this.positionList = res.data;
				} else {
					this.$message.info(res.msg);
				}
			})
		},
		// 返回
		backClick() {
			this.$router.back();
		},
		// 图片删除
		handleRemove(file, fileList) {
			console.log(file, fileList);
			this.img_list = [];
		},
		// 图片预览
		handlePictureCardPreview(file) {
			this.dialogImageUrl = file.url;
			this.previewImgDialog = true;
		},
		// 上传之前
		beforeUpload(file) {
			const fileName = file.name
			const fileType = fileName.substring(fileName.lastIndexOf('.'));
			if (
				fileType === '.jpg' ||
				fileType === '.png' ||
				fileType === '.jpeg' ||
				fileType === '.bmp' ||
				fileType === '.gif'
			) {

			} else {
				this.$message.error(this.$t('pleaseUploadTheCorrectImageType'));
				return false;
			}
		},
		// 上传图片成功
		onChange(file, fileList) {
			console.log(file);
			console.log(fileList);
			this.img_list = fileList;
		},
		// 提交
		btnClick() {
			if (this.isFlag) return;
			let image;
			if (this.img_list.length > 0) {
				image = this.img_list.map(item => item.response.data[0].id).join(",");
			} else {
				image = this.fileList.map(item => item.name).join(",");
			}

			if (!image) {
				this.$message.error(this.$t('tips.atLeastOnePhoto'));
				return;
			}
			let data = {
				id: this.id,
				thumb: image,
				bind_information_id: this.information_id
			};
			this.isFlag = true;
			this.$http.operation(data).then(res => {
				if (res.code == 1) {
					this.$message.success(res.msg);
					this.$router.back();
					this.isFlag = false;
				} else {
					this.isFlag = false;
					this.$message.error(res.msg);
				}
			})
		},
	}
}
</script>

<style scoped="" lang="less">
.person_infor {
	width: 926px;
}


.login_top {
	display: flex;
	align-items: center;
	padding: 24px 40px;
	border-bottom: 1px solid #F3F5F6;


	.img {
		display: flex;
		align-items: center;

		img {
			width: 18px;
			height: 18px;
		}
	}

	.login_title {
		margin-left: 8px;
		font-weight: bold;
		font-size: 18px;
		color: #F39800;

		&:hover {
			cursor: pointer;
		}
	}

	.front_img {
		display: flex;
		align-items: center;
		margin: 0 12px;

		img {
			width: 8px;
			height: 14px;
		}
	}
}

.all_list {
	margin: 0 50px;
	padding-top: 24px;
}

.list_item {
	text-align: left;
	padding: 24px 0;
	border-bottom: 1px solid #F3F5F6;
	font-size: 16px;

	&:last-child {
		border-bottom: none;
	}

	.list_item_top {
		span {
			color: #F39800;
		}
	}

	.list_item_bottom {
		font-size: 14px;
		margin-top: 28px;
		color: #4177DD;


		/deep/ .el-input__inner {
			width: 800px;
			border: none !important;
		}
	}
}

.btn {
	margin: 70px 0 40px;
	text-align: left;
	cursor: pointer;
	text-align: center;
	line-height: 48px;
	background: #F39800;
	font-size: 16px;
	color: #FFFFFF;
	width: 300px;
	height: 48px;
	border-radius: 4px;

	// div {
	// 	text-align: center;
	// 	display: inline-block;
	// 	min-width: 280px;
	// 	padding: 14px 0;
	// 	background: #F39800;
	// 	opacity: 1;
	// 	border-radius: 24px;
	// 	font-size: 16px;
	// 	color: #FFFFFF;
	// }

}
</style>
